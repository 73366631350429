<template>
  <div class="customerhome">
    
    <div class=" logo text-left">
      <Header title="Customer Help Center"></Header>
    </div>

    <div class="row2 mt-10">
      <div class="col-6 text-left">
        <div class="lako text-center" @click="gotoPage('/customer/support-chat')">
          <i class="fas fa-comments"></i>
          <h2>Support Chat</h2>
        </div>
      </div>
      <div class="col-6 text-left" @click="gotoPage('/customer/order-part')">
        <div class="lako text-center">
          <i class="fas fa-tools"></i>
          <h2>Request Part</h2>
        </div>
      </div>
      <div class="col-6 text-left" @click="gotoPage('/customer/rfq')">
        <div class="lako text-center">
          <i class="fas fa-inventory"></i>
          <h2>RFQ Request</h2>
        </div>
      </div>

      <div class="col-6 text-left" @click="gotoPage('/customer/request-history')">
        <div class="lako text-center">
          <i class="fas fa-question"></i>
          <h2>Request History</h2>
        </div>
      </div>
      <div class="col-6 text-left" @click="gotoPage('/customer/knowledge-base')">
        <div class="lako text-center">
          <i class="fas fa-books"></i>
          <h2>Knowledge Base</h2>
        </div>
      </div>
    </div>
   
  </div>
</template>
<script>
import Header from "@/components/Header";
export default {
  components: { Header },

  methods: {
    gotoPage: function(pageName) {
      console.log(pageName);

      this.$router.push(pageName);
    },
  },
};
</script>
<style scoped>
.col-6 {
  float: left;
  width: 50%;
  margin-bottom: 30px;
}
.col-4.home {
  float: left;
  width: 33.33%;
  margin-bottom: 30px;
}
img.img-fluid {
  width: 160px;
  height: 160px;
  margin-bottom: 11px;
}
.logo {
  padding-top: 20px;
  padding-left: 20px;
}
.text-right span input {
  border-bottom: 1px solid black;
  padding-left: 8px;
}
.fas {
    font-size: 2rem;
    color: maroon;
    margin-bottom: 11px;
}
</style>
