import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      isLoggedIn: false,
      role: "",
      name: "",
      email:"",
      company: "",
      companyName: "",
      repId: "",
      repName: "",
      id: "",
      playerId: "",
      region: "",
    },
    regions:['Admin','Midwest','Northeast','Southwest','International','Latin America','West','Canada','Admin']
  },
  mutations: {
    setLogin(state, payload){
      state.user.email = payload.email
      state.user.name = payload.name
      state.user.role = payload.role
      state.user.company = payload.company
      state.user.companyName = payload.companyName
      state.user.isLoggedIn = true;
      state.user.id = payload.id
      state.user.playerId = payload.playerId
      state.user.repId = payload.repId
      state.user.repName = payload.repName
      state.user.region = payload.region
    }
  },
  getters:{
    getRegions:(state)=>{
      return state.regions
    }
  },
  actions: {
    axSetLogin(store, payload){
     
      store.commit("setLogin",payload)
    },
    axLogout(store){
      store.commit("setLogin",{ email: "", isLoggedIn: false, role:"", name:"" , id: ""})
    }
  },
  modules: {
  },
  plugins: []
})
