<template>
  <div class="supportChat">
    <div class=" logo text-left">
      <Header title="Customer Help Center"></Header>
    </div>

    <v-container>  
      <div class="middleCenter"  v-if="!hasChatStarted">
        <v-btn color="primary" @click="startChat()"> Start Chat </v-btn>
      </div>
    </v-container>
    <v-container id="messageContainer" ref="messageContainer" v-if="hasChatStarted">
     
      <v-card
        :class="message.fromLako ? 'message fromLako' : 'message'"
        v-for="message in messages"
        :key="message.message"
      >
        <b>{{ message.fromName }} :</b>

        {{ message.message }}
        <span v-if="(message.mediaType = 'image')">
          <img :src="message.mediaImage" />
        </span>
        <hr />
        <small> {{ message.onDate | moment("from", "now") }}</small>
      </v-card>
    </v-container>
    <v-container  v-if="hasChatStarted">
      <v-text-field
        v-model="message"
        outlined
        label="Enter Message"
        @click:append-outer="sendMessage"
        @click:append="uploadFile"
        append-outer-icon="mdi-send"
        append-icon="mdi-paperclip"
      ></v-text-field>
      <input
        accept="image/*"
        type="file"
        ref="file"
        :style="showFileBox"
        @change="handleFile($event)"
      />
    </v-container>
    <img height="100px" :src="mediaImage" />
  </div>
</template>

<script>
import { db } from "../db";
import Header from "@/components/Header";
import { mapState } from "vuex";

export default {
  components: { Header },
  data() {
    return {
      message: "",
      mediaImage: "",
      showFileBox: "display:none",
      documents: [],
      chats: [],
      appSetting: {},
      messages: [],
    };
  },
  computed: {
    hasChatStarted(){
      return this.chats && this.chats.length > 0 ? true : false
    },
    chat(){
      return this.chats && this.chats.length > 0 ? this.chats[0] : null
    },
    ...mapState(["user"]),
  },
  mounted() {
    const sc = db.collection(`supportChat`).where("contactId", "==", this.user.id)
    this.$bind('chats',sc)
    const messages = db
      .collection(`supportChat/${this.chat.id}/messages`)
      .orderBy("onDate", "asc");
    this.$bind("messages", messages).then((documents) => {
      this.messages === messages;
      this.scrollToBottom()
    });
  }, 
  watch:{
    chat:{
      immediate: true,
      handler(n , o){
        const messages = db
          .collection(`supportChat/${this.chat.id}/messages`)
          .orderBy("onDate", "asc");
          this.$bind("messages", messages).then((documents) => {
            this.messages === messages;
            this.scrollToBottom()
        });
      }
    }
  },
  methods: {
    scrollToBottom() {
      const mc = document.getElementById("messageContainer");
      setTimeout(() => {
        mc.scrollTop += 2500;
      }, 500);
    },
    sendMessage() {
      const msg = {
        fromId: this.user.id,
        fromName: this.user.name,
        fromLako: false,
        companyId: this.user.company,
        message: this.message,
        onDate: new Date().toISOString(),
      };
      if (this.mediaImage.length > 10) {
        msg.mediaType = "image";
        msg.mediaImage = this.mediaImage;
      }

      db.collection(`supportChat/${this.chat.id}/messages`).add(msg);
      db.doc(`supportChat/${this.chat.id}`).update({lastDate: msg.onDate, repSeen: false})
      this.message = "";
      this.mediaImage = "";
      this.mediaType = "";
       this.scrollToBottom()
    },
    uploadFile() {
      this.$refs.file.click();
    },
    handleFile(e) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        console.log(reader.result);
        this.mediaImage = reader.result;
        this.message = "PHOTO";
        this.sendMessage();
      };
    },
    startChat(){
      let obj = {
        companyName: this.user.companyName,
        contactId: this.user.id,
        contactName: this.user.name,
        repId: this.user.repId,
        repName: this.user.repName
      }
      db.collection(`supportChat`).add(obj)
      console.log(obj)
    }
  },

  firestore: {
    documents: db.collection("appSettings"),
    appSettings: db.doc("appSettings/app"),
  },
};
</script>

<style scoped>
#messageContainer {
  height: 70vh;
  overflow: auto;
  width: 100%;
  border: 1px solid #eee;
  margin-top: 10px;
}

.message {
  padding: 10px;
  margin: 10px 5px;
  width: 70vw;
  float: right;
}
#messageContainer > .fromLako {
  color: #550011;
  float: left;
  background-color: #eee;
}
hr {
  border-top: 1px solid #eee;
}
.inputmessage {
  position: absolute;
  bottom: 10px;
  width: 100%;
}
img.img-fluid {
  float: right;
  margin-right: 21%;
  position: relative;
  margin-top: -22px;
  cursor: pointer;
  height: 17px;
  width: 20px;
}
.inputmessage input {
  border: 1px solid;
  margin: 0 20%;
  width: 60%;
  padding-left: 10px;
}

.messageuser.text-right {
  float: right;
  width: 100%;
}

.messageuser.text-right p {
  border: 2px solid black;
  float: right;
  padding: 0px 14px;
  background: #7070e0;
  color: white;
}
.messagesupport.text-left p {
  float: left;
  border: 2px solid black;
  background: antiquewhite;
  padding: 0px 13px;
}
.messagesupport.text-left {
  float: left;
  width: 100%;
}
.middleCenter{
  height: 100%;
  margin:  50% 0px;
  text-align: center;
}
</style>
