<template>
  <div class="adminuser">
    <div class="row">
      <div>
        <Header title="Administrator Panel"> </Header>
      </div>
    </div>

    
    <v-container>
  <h4> Requests</h4>
    <edit-customer v-model="showEditCompany" :currentCompany="selectedCompany" :companyId="selectedCompanyId" @close="showEditCompany = false" />
  <v-list two-line>
    <v-list-item v-model="selected" multiple v-for="customer in requests"  v-bind:key="customer.id" >
     <v-col cols="9" @click="editCompany(customer)"> 
      
      <span class="lst-label">  <v-icon  color="red darken-4">mdi-office-building</v-icon>   </span> {{customer.companyName}}  <br>
       <span class="lst-label">  <v-icon color="red darken-4" >mdi-account</v-icon></span> {{customer.contactName}} <br>
         <span class="lst-label">  <v-icon color="red darken-4" >mdi-delta</v-icon></span> {{customer.type  | uppercase }} <br>
           <v-chip> {{ customer.dateCreated | moment("from", "now") }}</v-chip>
     </v-col>
     <v-col cols="2">
         
          <v-icon v-if="customer.status == 'new'" color="red">mdi-star</v-icon>
              <v-icon v-if="customer.status != 'new'" color="green"
                >mdi-check</v-icon
              >
         
     </v-col>
     <v-col>
         <v-icon >mdi-eye</v-icon
              >
     </v-col>
    </v-list-item>
  </v-list>
</v-container>
  </div>
</template>

<script>
import { db } from "../db";
import Header from "@/components/Header";

export default {
  components: { Header },
  data() {
    return {
      currentRequest: {},
      showRequest: false,
      requests: [],
      headers: [
        {
          text: "Date",
          align: "start",
          sortable: true,
          value: "dateCreated",
        },

        { text: "Type", value: "type" },
        { text: "Company", value: "companyName" },
        { text: "Contact", value: "contactName" },
        { text: "Status" },
        { text: "--" },
      ],
    };
  },
  methods: {
    gotoPage: function(pageName) {
      this.$router.push(pageName);
    },
    openRequest: function(request) {
      this.showRequest = true;
      this.currentRequest = request;
    },
  },
  firestore: {
    requests: db.collection("customerRequests"),
    appSettings: db.doc("appSettings/app"),
  },
};
</script>
