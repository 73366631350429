<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
          NEW CONTACT
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          New  Contact 
        </v-card-title>

        <v-card-text>
        
          <v-form
           ref="form"
          >
          <v-select
       
           label="Company"
           placeholder="select"
           :items="myCompanies"
           item-value="id"
           item-text="name"
           ref="selectedCompany"
          >
          </v-select>
        
            <v-text-field
              v-model="user.name"
              :rules="[v => !!v || 'Name is required']"
              label="Name"
            ></v-text-field>
            <v-text-field
              v-model="user.email"
             :rules="[v => !!v || 'Email is required']"
              
              label="Email"
            ></v-text-field>
            <v-text-field
              v-model="user.sms"
              label="Mobile/SMS"
            ></v-text-field>
            <v-text-field
              v-model="user.password"
              :counter="25"
              class="idk"
              color="accent"
              label="Password"
              :rules="[v => !!v || 'Password is required']"
            ></v-text-field>

          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="warning" outlined @click="dialog = false">
            CANCEL
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="save()">
            save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from "../../db";

export default {
  props:['companyName', 'companyId'],
  data() {
    return {
      dialog: false,
      user:{},
      reps:[],
      myCompanies:[],
      selectedCompany: {}
    };
  },
  mounted(){
    this.user.companyId = this.companyId
    this.user.companyName = this.companyName
    this.getMyCompanies();
   
    
  },
  methods:{
      save(){
         if(this.validate()){
           this.user.companyName = this.$refs.selectedCompany.selectedItems[0].name
           this.user.companyId = this.$refs.selectedCompany.selectedItems[0].id
           this.user.status = 'active'
           this.user.role = 'customer'
           this.user.password = btoa(this.user.password)
           this.addToFirestore()
           this.dialog = false
           this.user = {}
         }
          
      },
      validate() {
       return true// this.$refs.form.validate()
      },
       async getMyCompanies(){
      
        const ITEMS = db.collection('companies').orderBy("name")
        await this.$bind('myCompanies', ITEMS);
       
      },
      async addToFirestore(){
        console.log('Adding:', this.user)
        await db.collection("appUsers").add(this.user)
      }
  },
  computed:{
    regions(){
      return this.$store.state.regions;
    },
    currentUser(){
      return this.$store.state.user;
    }
  },
  firestore: {
    reps: db.collection('appUsers').where("role","==","lako"),
    appSettings: db.doc('appSettings/app')
  },

};
</script>
<style lang="sass" scoped>
 .idk 
   color: "red"
</style>
 