<template>
  <div class="supportChat">
    <Header title="Lako Rep Panel"> </Header>

    <v-container class="allContainer">
      <h4> Messages</h4>
      <br>
      <v-row no-gutters v-for="obj in supportChats" v-bind:key="obj.id" @click="gotoPage(obj.id)">
        <v-col class="pa-2" cols="8" sm="8">
          {{ obj.contactName }} <br />
          <small>{{ obj.companyName }}</small>
        </v-col>
        <v-col sm="4">
          {{ obj.lastDate | moment('from', 'now') }} 
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { db } from "../db";
import Header from "@/components/Header";
import { mapState } from "vuex";

export default {
  components: { Header },
  data() {
    return {
      selectCompanyId: "democompany",
      message: "",
      mediaImage: "",
      showFileBox: "display:none",
      documents: [],
      appSetting: {},
      supportChats: [],
      currentDate:'',
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  mounted() {
    this.currentDate = new Date().getTime();
    const supportChats = db
      .collection(`supportChat`)
      .orderBy("lastDate", "desc");
    this.$bind("supportChats", supportChats).then((chats) => {
      // this.supportChats === chats;
      // this.scrollToBottom();
    });
  },
  methods: {
    scrollToBottom() {
      const mc = document.getElementById("messageContainer");
      setTimeout(() => {
        mc.scrollTop += 2500;
      }, 500);
    },
    sendMessage() {
      const msg = {
        fromId: 1,
        fromName: this.user.name,
        fromLako: false,
        companyId: this.user.company,
        message: this.message,

        onDate: new Date().toISOString(),
      };
      if (this.mediaImage.length > 10) {
        msg.mediaType = "image";
        msg.mediaImage = this.mediaImage;
      }

      db.collection("companies/democompany/supportChat").add(msg);
      this.message = "";
      this.mediaImage = "";
      this.mediaType = "";
      this.scrollToBottom();
    },
    uploadFile() {
      this.$refs.file.click();
    },
    handleFile(e) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        console.log(reader.result);
        this.mediaImage = reader.result;
        this.message = "PHOTO";
        this.sendMessage();
      };
    },
    gotoPage(id){
      //Set Current Support Chat Data then redirect
      
      this.$router.push(`support-chat/${id}`)
    }
  },

  firestore: {
    documents: db.collection("appSettings"),
    appSettings: db.doc("appSettings/app"),
  },
};
</script>

<style scoped>
#messageContainer {
  display: inline;
  height: 70vh;
  overflow: auto;
  width: 100%;
  border: 1px solid #eee;
  margin-top: 10px;
}

.message {
  padding: 10px;
  margin: 20px;
  width: 50vw;
  float: right;
}
#messageContainer > .fromLako {
  color: #eee;
  float: left;
  background-color: #0d4789;
}
.allContainer {
  display: flex;
  flex-direction: row;
}
button {
  padding: 19px;
  font-size: 18px;
  font-weight: 800;
}
button.active {
  background: blue;
  width: 100%;
  padding: 13px 0px;
  font-size: 1.3rem;
  text-align: center;
  color: #eee;
}
.messageuser.text-right {
  float: right;
  width: 100%;
}
.text-right span input {
  border-bottom: 1px solid black;
  padding-left: 8px;
}

.row {
  padding: 0px 10px;
}
.users {
  float: left;
  display: inline;
  width: 16%;
  height: 70vh;

  overflow: scroll;
  border: 1px solid;
  overflow-x: hidden;
}

.row {
  float: left;
  width: 84%;
  max-height: 76vh;
  min-height: 76vh;
  overflow: scroll;
  overflow-x: hidden;
}
</style>
