<template>
  <div class="orderpart text-left">
    <div class=" logo text-left">
      <Header title="Customer Help Center"></Header>
    </div>

    <div class="body">
      <h3>RFQ </h3>
      <p>Any Special Notes can go here</p>

      <v-container>
        <v-row>
          <v-col cols="6">
          <v-select
          :items="packageTypeItems"
          item-text="txt"
          item-value="val"
          label="Package Type"
          v-model="packageType"
          outlined
        ></v-select></v-col>
        </v-row>
        <v-row>
          <v-col>
            <!-- <v-select
            :items="filteredmachineManufacturers"
            item-text="name"
            item-value="name"
              
              label="Machine Manufacturer"
              outlined
              v-model="machineManufacturer"
            ></v-select> -->
            <v-text-field label="Machine Manufacturer" v-model="machineManufacturer" outlined></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Film Type"
              outlined
               
              v-model="filmType"
            ></v-text-field>
          </v-col>
        </v-row>
         <v-row>
          <v-col>
            <v-text-field
              label="Part #"
              outlined
               
              v-model="partNumber"
            ></v-text-field>
          </v-col>
        </v-row>
         <v-row>
          <v-col>
            <v-text-field
              label="Purchase Order #"
              outlined
               
              v-model="purchaseOrder"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              outlined
              label="Additional Comments"
              v-model="comments"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>

      <v-row>
        <v-col cols="12" class="image text-center">
          <v-btn color="primary" :disabled="!formValid" @click="save()"
            >Send Request</v-btn
          >
        </v-col>
      </v-row>
      <v-snackbar color="accent" v-model="snackbar" :timeout="timeout">
        Request Sent. Thank You!

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header";
import { mapState } from "vuex";
import { db } from "../db";

export default {
  components: { Header },
  data() {
    return {
      snackbar: false,
      timeout: 5000,
      packageType:"",
      packageTypeItems:[{txt:"Wrapper / HFFS", val:'HFFS'}, {txt:"Bag / VFFS",val:"VFFS"}],
      machineManufacturer:'',
      machineManufacturerItems: [],
     
      filmType:'',
      partNumber:'',
      purchaseOrder:'',
      comments:'',
      rfqItem: {
        comments: "",
      },
    };
  },
  computed: {
    formValid() {
      return (this.machineManufacturer !=""  && this.filmType != "") && this.partNumber !=""
        ? true
        : false;
    },
    filteredmachineManufacturers(){
        return this.machineManufacturerItems.filter( mm =>{
            return mm.type == this.packageType || mm.type == 'all' ? true  : false
        })
    }
    ,
   
    ...mapState(["user"]),
  },
  methods: {
    gotoPage: function(pageName) {
      this.$router.push(pageName);
    },
    save() {
      
      this.rfqItem.companyId = this.user.company;
      this.rfqItem.companyName = this.user.companyName;
      this.rfqItem.contactName = this.user.name;
      this.rfqItem.contactEmail = this.user.email;
      this.rfqItem.status = "new";
      this.rfqItem.type = "rfq";
      this.rfqItem.dateCreated = new Date().toLocaleString().toString();

      this.rfqItem.packageType = this.packageType
      this.rfqItem.machineManufacturer = this.machineManufacturer
      this.rfqItem.filmType = this.filmType
      this.rfqItem.partNumber = this.partNumber
      this.rfqItem.purchaseOrder = this.purchaseOrder
      this.rfqItem.comments = this.comments

      this.packageType = ""
      this.machineManufacturer = ""
      this.filmType = ""
      this.partNumber = ""
      this.purchaseOrder = ""
      this.comments = ""

      

      db.collection("customerRequests").add(this.rfqItem);
      this.snackbar = true;
      this.rfqItem = {};
    },
  },
  firestore: {
    machineManufacturerItems: db.collection("machineManufacturers"),
   
  },
};
</script>
<style scoped>
img.img-fluid {
  width: 90px;
}

.body {
  text-align: center;
}
.text-right span input {
  border-bottom: 1px solid black;
  padding-left: 8px;
}
.text-right span input {
  border-bottom: 1px solid black;
  padding-left: 8px;
}

.head.text-center {
  margin-bottom: 20px;
}
.image textarea {
  float: left;
  width: 40%;
  padding-left: 10px;
  border: 2px solid black;
  margin-left: 40px;
  margin-bottom: 20px;
}
</style>
