import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import { firestorePlugin } from 'vuefire'
import VueMoment from 'vue-moment'


//ONESIG
function loadOneSignal() {

  (window as any).OneSignal = (window as any).OneSignal || [];
  (window as any).OneSignal.push(() => {

  (window as any).OneSignal.getUserId(function(userId: string) {
    (window as any).OneSignal.showNativePrompt();
    console.log("OneSignal User ID:", userId);

  });
  (window as any).OneSignal.init({
    appId: process.env.VUE_APP_ONESIGNAL,
    safari_web_id: process.env.SAFARI_WEB_ID,
    allowLocalhostAsSecureOrigin: process.env.NODE_ENV !== 'production',
  });
});
(window as any).OneSignal.isPushNotificationsEnabled(function(isEnabled: boolean) {
  if (isEnabled) {
      // user has subscribed
      (window as any).OneSignal.getUserId( function(userId: string) {
          console.log('player_id of the subscribed user is : ' + userId);
          localStorage.playerId = userId;
          // Make a POST call to your server with the user ID        
      });
  }
  else{
    console.log("ok")
  }
});
}


 // loadOneSignal()



Vue.use(firestorePlugin)
Vue.config.productionTip = false
Vue.use(VueMoment);
Vue.filter('capitalize', function (value: any) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})
Vue.filter('uppercase', function (value: any) {
  if (!value) return ''
  value = value.toString()
  return value.toUpperCase() 
})
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
