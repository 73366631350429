<template>
  <div class="supportChat">
    <Header title="Lako Rep Panel">
     
        <span slot="subheader">
       {{chat.contactName}} @ {{chat.companyName}} {{currentChat}}
      </span> 
       </Header>

    <v-container class="allContainer">
      
      <div id="messageContainer">
        <v-card
          :class="message.fromLako ? 'message fromLako' : 'message'"
          v-for="message in messages"
          :key="message.id"
        >
          <b>{{ message.fromName }} :</b>

          {{ message.message }}
          <span v-if="(message.mediaType = 'image')">
            <img :src="message.mediaImage" />
          </span>
          <hr />
          <small> {{ message.onDate | moment("from", "now") }}</small>
        </v-card>
      </div>
    </v-container>
    <v-container>
      <v-text-field
        v-model="message"
        outlined
        label="Enter Message"
        @click:append-outer="sendMessage"
        @click:append="uploadFile"
        append-outer-icon="mdi-send"
        append-icon="mdi-paperclip"
      ></v-text-field>
      <input
        accept="image/*"
        type="file"
        ref="file"
        :style="showFileBox"
        @change="handleFile($event)"
      />
    </v-container>
    <img height="100px" :src="mediaImage" />
  </div>
</template>

<script>
import { db } from "../db";
import Header from "@/components/Header";
import { mapState } from "vuex";

export default {
  components: { Header },
  data() {
    return {
      selectCompanyId:'democompany',
      message: "",
      mediaImage: "",
      showFileBox: "display:none",
      documents: [],
      appSetting: {},
      messages: [],
      chat:{},
    };
  },
  computed: {
    currentChat(){
      return this.$route.params.id 
    },
    ...mapState(["user"]),
  },
  mounted() {
    const sc = db.doc(`supportChat/${this.currentChat}`)
    this.$bind('chat', sc);
    const messages = db
      .collection(`supportChat/${this.currentChat}/messages`)
      .orderBy("onDate", "asc");
    this.$bind("messages", messages).then((documents) => {
     // this.messages === messages;
      this.scrollToBottom();
    });
  },
  methods: {
    scrollToBottom() {
      const mc = document.getElementById("messageContainer");
      setTimeout(() => {
        mc.scrollTop += 2500;
      }, 500);
    },
    sendMessage() {
      const msg = {
        fromId: 1,
        fromName: this.user.name,
        fromLako: true,
        companyId: this.user.company,
        message: this.message,

        onDate: new Date().toISOString(),
      };
      if (this.mediaImage.length > 10) {
        msg.mediaType = "image";
        msg.mediaImage = this.mediaImage;
        msg.repSeen = true;
      }

      db.collection(`supportChat/${this.currentChat}/messages`).add(msg);
      db.doc(`supportChat/${this.currentChat}`).update({lastDate: msg.onDate, repSeen: true})
      this.message = "";
      this.mediaImage = "";
      this.mediaType = "";
      this.scrollToBottom();
    },
    uploadFile() {
      this.$refs.file.click();
    },
    handleFile(e) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
      
        this.mediaImage = reader.result;
        this.message = "PHOTO";
        this.sendMessage();
      };
    },
  },

  firestore: {
    documents: db.collection("appSettings"),
    appSettings: db.doc("appSettings/app"),
  },
};
</script>

<style scoped>
#messageContainer {
  display: inline;
  height: 70vh;
  overflow: auto;
  width: 100%;
  border: 1px solid #eee;
  margin-top: 10px;
}

.message {
  padding: 10px;
  margin: 10px 5px;
  width: 70vw;
  float: right;
}
#messageContainer > .fromLako {
  color: #550011;
  float: left;
  background-color: #eee;
}
.allContainer {
  display: flex;
  flex-direction: row;
}
button {
  padding: 19px;
  font-size: 18px;
  font-weight: 800;
}
button.active {
  background: blue;
  width: 100%;
  padding: 13px 0px;
  font-size: 1.3rem;
  text-align: center;
  color: #eee;
}
.messageuser.text-right {
  float: right;
  width: 100%;
}
.text-right span input {
  border-bottom: 1px solid black;
  padding-left: 8px;
}

.row {
  padding: 0px 10px;
}
.users {
  float: left;
  display: inline;
  width: 16%;
  height: 70vh;

  overflow: scroll;
  border: 1px solid;
  overflow-x: hidden;
}

.row {
  float: left;
  width: 84%;
  max-height: 76vh;
  min-height: 76vh;
  overflow: scroll;
  overflow-x: hidden;
}
</style>
