<template>
   <div class="partrequest">
       
         <div class=" logo text-left">
      <Header title="Customer Help Center"></Header>
    </div>
         
         <div class="body">
               <div class="table table-resposive">
                    <table>
                        <thead>
                            <tr>
                            <th>Date</th>
                            <th>Image</th>
                            <th>Serial Number</th>
                            <th>Quantity</th>
                            <th>Comments</th>
                            <th></th>
                            </tr>
                         <hr/>   
                        </thead>
                         
                        <tbody>
                            <tr v-for="hist in historyItems" :key="hist.type" >
                                <td>{{hist.dateCreated}}<br/>{{hist.contactName}}</td>
                                <td><img v-if="hist.imageUrl" :src="hist.imageUrl"></td>
                                <td>{{hist.serial}}</td>
                                <td>{{hist.qty}}</td>
                                <td>{{hist.comments}} </td>
                               <td><button type="button">Re-Order</button></td>
                                </tr>
                              
                                
                        </tbody>
                    </table>
                 
              </div>
             </div>
       </div>
</template>
<script>
import { db } from  '../db'
import Header from '@/components/Header'
export default {
    components:{Header},
    data(){
        return {
            historyItems: []
        }
    },
    firestore: {
        historyItems: db.collection("customerRequests").where("type","==","porder")
    }
}
</script>
<style scoped>
img.img-fluid {
    width: 90px;
}
hr{
    width: 711%;
    height: 2px;
    margin-bottom: 7px;
    /* border-color: rgba(0, 0, 0, 0.12) */
}
 table {
     width: 100%;
     text-align:center;
     }
     td img {
    border: 1px solid;
    width: 200px;
    height: 126px;
}
.head.text-center {
    margin-bottom: 35px;
}
th {
    padding-bottom: 20px;
    color: hsla(239, 71%, 20%, 0.900) ;
    /*  */
}
.text-right span input {
    border-bottom: 1px solid black;
padding-left: 8px;
}
button {
    height: 50px;
    box-shadow: 3px 3px black;
    width: 100px;
    color: white !important;
    border: 2px solid black;
    background:rgba(15, 16, 87, 0.900);
    margin-top: 15px;
}

</style>