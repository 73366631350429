<template>
  <div class="text-center">
  
    <v-dialog v-model="show" width="500">
     

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Edit Customer
        </v-card-title>

        <v-card-text>
        
          <v-form ref="form">
            <v-text-field
              v-model="company.name"
              label="Name"
            ></v-text-field>
            <v-text-field
              v-model="company.phone"
              label="Phone"
            ></v-text-field>
              <v-select
           v-model="company.region"
          label="Region"
          :items="regions"
        ></v-select>
          
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="warning" outlined @click="close()">
            CANCEL 
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="save()">
            save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from "../../db";

export default {
  props:['value', 'currentCompany', 'companyId'],
  data() {
    return {
      
     
    };
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('close', value)
      }
    },
    company: {
        get() { return this.currentCompany }
    },
    regions(){
      return this.$store.state.regions
    }

  },
  methods:{
      save(){
         if(this.validate()){
          
           //this.company.repName = this.$refs.selectedRep.selectedItems[0].name
          
           this.addToFirestore()
           this.close()
           
         }
          
      },
      validate() {
       return this.$refs.form.validate()
      },
      async addToFirestore(){
          
        await db.doc(`companies/${this.companyId}`).update(this.company)
      },
      close(){
        
           this.$emit('close', false)
      }
  },
  firestore: {
    reps: db.collection('appUsers').where("role","==","lako"),
    appSettings: db.doc('appSettings/app')
  },
};
</script>
<style lang="sass" scoped>
 .idk 
   color: "red"
</style>
 