<template>
    
        <v-dialog v-model="show" width="500">
             <v-card>
        <v-card-title class="headline grey lighten-2">
           <h3>Notifications</h3> 
         
        </v-card-title>

        <v-card-text v-for="item in items" v-bind:key="item.id">
           <div :class="item.isNew ? 'notif_body bold' : 'notif_body'">
            {{item.body}}
            <div class="text-right" v-if="item.isNew">
                <v-btn color="primary" small @click="markAsRead(item.id)">
                    MARK READ
                </v-btn>
            </div>
            <div class="text-right" v-if="!item.isNew">
                <v-icon>mdi-check</v-icon>
            </div>
           </div>
           <div class="text-right">
               {{item.onDate  | moment("from","now")}}
           </div>
        </v-card-text>
       
             </v-card>
        </v-dialog>
 
</template>

<script>
import { db } from "../db";
import { mapState } from "vuex";
export default {
  props:['value'],
  data() {
    return {
      items: [],
      newItems:[]
     
    };
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('close', value)
      }
    },
    
     ...mapState(["user"]),
  },watch:{
      value:{
          immediate: true,
          handler(v, o){
              console.log(v)
              this.getItems()
              this.getNewItems();
          }
          
      }
  },
  mounted(){
      console.log("mounted")
     

  },
  methods:{
      markAsRead(id){
        console.log('read',id)
        db.doc(`appUsers/${this.user.id}/notifications/${id}`).update({isNew: false})
        this.getNewItems()
      },
      close(){
           this.$emit('close', false)
      },
      async getItems(){
      
        const ITEMS = db.collection(`appUsers/${this.user.id}/notifications`);
        await this.$bind('items', ITEMS);
       
      },
      async getNewItems(){
      
        const ITEMS = db.collection(`appUsers/${this.user.id}/notifications`).where("isNew","==", true);
        await this.$bind('newItems', ITEMS);
       
      }
  },
  firestore: {
   // appSettings: db.doc('appSettings/app'),
   
  },
};
</script>
<style lang="sass" scoped>
 .idk 
   color: "red"
 .notif_body
   color: #444
   border-bottom: 1px solid #ccc
   padding: 15px 5px
   font-weight: 100
 .bold
   font-weight: bold
   color: #000077
</style>
 