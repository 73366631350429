<template>
    
    <div class="myrequest">
          
        <Header title="Lako Rep Panel"> </Header>
         
     
      
         
    <v-container>
  <h4> Requests</h4>
    <edit-customer v-model="showEditCompany" :currentCompany="selectedCompany" :companyId="selectedCompanyId" @close="showEditCompany = false" />
  <v-list two-line>
   <template v-for="obj in customerRequests">
    <v-list-item v-model="selected" multiple  v-bind:key="obj.id" >
   
     <v-col cols="6" @click="editCompany(obj)"> 
         <v-chip color="blue darken-3" > <span style="color:white">{{ obj.dateCreated  }}</span></v-chip> <br>
      <span class="lst-label">  <v-icon  color="red darken-4">mdi-office-building</v-icon>   </span> {{obj.companyName}}  <br>
       <span class="lst-label">  <v-icon color="red darken-4" >mdi-account</v-icon></span> {{obj.contactName}} <br>
         <span class="lst-label">  <v-icon color="red darken-4" >mdi-delta</v-icon></span> {{obj.type  | uppercase }} <br>
        
     </v-col>
     <v-col cols="6">
          <div v-if="obj.status =='new'">
                        <v-btn color="primary" @click="acknowledge(obj.id)">Acknowledge</v-btn>
                      </div>
                    <div v-if="obj.status =='acknowledged'">
                          <v-btn color="danger" :disabled="true" @click="acknowledge(obj.id)">Acknowledged</v-btn>
                             <br> <small>{{obj.acknowledgedBy}} on {{obj.acknowledgedDate | moment("MM / DD")}}</small>
                      </div>
     </v-col>
    
    </v-list-item>
    <v-list-item   v-bind:key="obj.id"  style="border-bottom: 1px solid gray">
        <v-col cols="12">
            <div v-if="obj.type=='porder'">
                       <div>Serial: {{obj.serial}}</div>
                       QTY: {{obj.qty}}
                       <div>
                           <img :src="obj.imageUrl">
                       </div>
            </div>
                   <div v-if="obj.type=='rfq'">
                       <div>
                           Package Type: {{obj.packageType}}
                       </div>
                       <div>
                           Machine Manufacturer: {{obj.machineManufacturer}}
                       </div>
                       <div>
                           Film Type: {{obj.filmType}}</div>
                       Part #: {{obj.partNumber}}
                       <div v-if="obj.purchaseOrder && obj.purchaseOrder.length > 0">
                          PO: {{obj.purchaseOrder}}
                       </div>
                   </div>
                   <i>{{obj.comments}}</i>
        </v-col>
       
   
    </v-list-item>
    
   </template>
  </v-list>
</v-container>
    </div>
</template>
<script>
import Header from '@/components/Header'
import { db } from '../db'
import { mapState } from 'vuex'
    export default {
        components: {Header},
        data(){
            return{
                customerRequests:[],
                 headers: [
                    {
                    text: "Date",
                    align: "start",
                    sortable: true,
                    value: "name",
                    },

                    { text: "Type", value: "phone" },
                    { text: "Description", value: "repName" },
                    { text: "Status", value: "status" },
                    { text:""}
                    ],
            }
        },
        methods:{
            acknowledge(reqId){
                db.doc(`customerRequests/${reqId}`).update({
                    status:"acknowledged",
                    acknowledgedBy: this.user.name,
                    acknowledgedDate: new Date().toISOString()
                })
                console.log(reqId)
            }
        },
        computed:{
            ...mapState(['user'])
        },
        firestore:{
            customerRequests: db.collection('customerRequests')
        }
    }
</script>
<style scoped>
.req {
    float: right;
    text-align: center;
    padding: 10px 23px;
}

th {
    color: #5d9edf;
    text-decoration: underline;
}
td {
    padding: 10px 0px;
}
.req img {
    width: 47px;
}

.logo.text-left {
    float: left;
    padding: 10px;
}
table {
    float: left;
    width: 100%;
    margin-top: 30px;
}
</style>