<template>
<div>
    <v-card
    class="mx-auto overflow-hidden"
    height="125"
    width="100vw"
  >
    <v-system-bar color="deep-blue darken-3"></v-system-bar>

    <v-app-bar
      color="grey lighten-1"
      dark
      prominent
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

     <v-card-text class="subheader text-center">
         <img alt="vue logo" style="max-width:100px" src="@/assets/lakotools.png">
         <br>
         {{title}}  <p class="subheader"> <slot name="subheader"></slot></p>
    </v-card-text>

      <v-spacer></v-spacer>

        <v-badge
        :content="newItemsCount"
        :value="newItemsCount"
        color="red"
        overlap
        offset-x="20"
        offset-y="15"
        v-show="user.role =='lako'"
            >
      <v-btn v-show="user.role =='lako'" icon  @click="showNotificationsDialog = true;" >
         <v-icon >mdi-bell</v-icon>
      </v-btn>  
      </v-badge>

     <v-spacer></v-spacer>
      <v-btn icon  @click="logout()">
         <v-icon >mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>

   

  </v-card>
        <v-navigation-drawer
      v-model="drawer"
      absolute
      bottom
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item v-for="item in bars[user.role]" v-bind:key="item.link" :to="item.link" >
            <v-list-item-title>{{item.caption}}</v-list-item-title>
          </v-list-item>

        
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  
   <v-divider></v-divider>
     <Notifications v-model="showNotificationsDialog" @close="showNotificationsDialog = false" ></Notifications>
</div>
 
    
</template>
<script>
import Notifications from '@/views/rep/Notifications.vue';
import { db } from "../views/db";
import {mapState, mapActions} from 'vuex'
export default {
  components: { Notifications },
     props:['title', 'showNotifications'],
     data(){
         return{
            currentLink: "/admin/customers",
            drawer: false,
            group: null,
            showNotificationsDialog: false,
            newItems:[],
            messages: 0,
            bars:
                {
                    admin:[
                     {
                         link:"/admin/home",
                         caption: "Home"
                     },
                     {
                         link:"/admin/users",
                         caption: "Lako Reps"
                     }
                     ,
                     {
                         link:"/admin/customers",
                         caption: "Customers"
                     }, {
                         link:"/admin/manage-kbase",
                         caption: "Manage KBase"
                     },
                     {
                         link:"/admin/all-requests",
                         caption: "All Requests"
                     }
                    ],
                    lako:[
                     {
                         link:"/rep/home",
                         caption: "Home"
                     },
                     {
                         link:"/rep/support-chat",
                         caption: "Customer Support"
                     },
                     {
                         link:"/rep/customers",
                         caption: "My Customers"
                     },
                     {
                         link:"/rep/requests",
                         caption: "My Requests"
                     }
                    ],
                    customer:[
                     {
                         link:"/customer/home",
                         caption: "Home"
                     },
                     {
                         link:"/customer/support-chat",
                         caption: "Support Chat"
                     },
                     {
                         link:"/customer/order-part",
                         caption: "Request Part"
                     },
                     {
                         link:"/customer/rfq",
                         caption: "RFQ"
                     },
                     {
                         link:"/customer/request-history",
                         caption: "Request History"
                     },
                     {
                         link:"/customer/knowledge-base",
                         caption: "Knowledge Base"
                     }
                    ]

                }
             
         }
     },
     computed: {
        currentRouteName() {
            return this.$route.path;
        },
        newItemsCount(){
            return this.newItems ? this.newItems.length : 0
        },
        ...mapState(['user'])
    },
    mounted(){
        this.getNewItems()
    },
    methods:{
        logout(){
            this.axLogout()
           this.$router.replace("/")
        },
      async getNewItems(){
      
        const ITEMS = db.collection(`appUsers/${this.user.id}/notifications`).where("isNew","==", true);
        await this.$bind('newItems', ITEMS);
       
      },
        ...mapActions(['axLogout'])
    },
    watch: {
      group () {
        this.drawer = false
      },
    },
}
</script>
<style  scoped>
.v-icon {
    color:#0E3849 !important;
}
    div.header-container{
        display: flex;
        flex-direction: row;
        width: 95vw;
        margin-left: 17px;
        margin-top: 20px;
        align-content: flex-start;
        justify-items: auto;
    }
    div.header-container > .logo{
       flex: 0vh ;
        
    }
    div.header-container > .title{
        flex: 5;
        padding: 10px 30px;
        font-size: 1.7rem  !important;
        color: maroon;
        align-items: center;
      
    }
    div.header-container > .actions{
        flex: 2
    }
    .navbar {
        margin-top: 15px;
        text-decoration: none;
        margin-left: 17px;
       
    }
    .col > a {
        text-decoration: none;
        border-bottom: 1px solid darkgrey;
       
        color:maroon;
    }
    .col > a.active {
         border-top: 2px solid navy;
         font-weight: bold;
         border-bottom: 2px solid navy;
    }
    .subheader{
        font-size: .9rem;
    }
</style>