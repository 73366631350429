<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
          NEW CUSTOMER
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          New Customer
        </v-card-title>

        <v-card-text>
          This form is for creating LAKO TOOL users only.
          <v-form>
            <v-text-field
              v-model="company.name"
              label="Name"
            ></v-text-field>
            <v-text-field
              v-model="company.phone"
              label="Phone"
            ></v-text-field>
         
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="warning" outlined @click="dialog = false">
            CANCEL
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="save()">
            save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from '../../db'
export default {
  data() {
    return {
      dialog: false,
      reps:[],
      company:{}
    };
  },
  methods:{
      save(){
          
         // this.company.repName = this.$refs.selectedRep.selectedItems[0].name
          this.dialog = false
          db.collection('companies').add(this.company)
          this.company = {}
         
          
      },
      validate() {
       return true //this.$refs.form.validate()
      },
  },
  firestore: {
    reps: db.collection('appUsers').where("role","==","lako"),
    appSettings: db.doc('appSettings/app')
  },
};
</script>
<style lang="sass" scoped>
 .idk 
   color: "red"
</style>
