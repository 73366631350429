//import firebase from 'firebase/app'
import 'firebase/firestore'
import * as firebase from 'firebase'
// Get a Firestore instance

const firebaseConfig = {
    apiKey: "AIzaSyDZxQT5FmfIoAAYwBOwkisTZu3tUipbtZY",
    authDomain: "lakotool-9402d.firebaseapp.com",
    projectId: "lakotool-9402d",
    storageBucket: "lakotool-9402d.appspot.com",
    messagingSenderId: "859461788254",
    appId: "1:859461788254:web:d0ad01735f0d96db084acf",
    measurementId: "G-N37CPSXB7S"
  };
  // Initialize Firebase RU!321@ru
 

export const db = firebase
  .initializeApp(firebaseConfig)
  .firestore()

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { Timestamp, GeoPoint } = firebase.firestore
export { Timestamp, GeoPoint }

// if using Firebase JS SDK < 5.8.0
db.settings({ timestampsInSnapshots: true })

// -- This will be removed later
firebase.auth().signInWithEmailAndPassword('regular_user@webapp.com','RU!321@ru')
.then(() => {
 
 console.log("WE ARE CONNECTED")
})
.catch(error => {
  alert(error.message);
});
//-- Remove this later .... we'll have a admin_user and regular_user later based on the full login