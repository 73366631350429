<template>
<div class="adminuser">
   <div class="row">
        <div >
          <Header title="Administrator Panel">
          <new-kbase-item></new-kbase-item>
        </Header>
        </div>
        
        
    </div>
 
 <!-- <v-container>
    
      <template
        v-slot:item="{ item }">
      <tr :data-id="item.id">
        
        <td>
         {{ item.category }}  
         
       </td>
       <td>
          
           <i :class="'fal ' + faIcon(item.mediaType)"  style="font-size:1.4rem;color: gray"></i>
           
       </td>
       <td class="dialogLink" @click="editItem(item)">
           {{ item.title }}
       </td>
       <td>
           {{ item.dateUpdated | moment("MM/D/YYYY") }}
       </td>
       <td @click="toggleItemStatus(item.id,item.status)">
          
           <status-icon v-bind:status="item.status" />
       </td>
       <td>
          <a href="">View Article</a>
       </td>

      
    </tr>
  </template>
      </v-data-table> -->



<v-container>
  <h4> Knowledge Base</h4>
  <new-kbase-item></new-kbase-item>
    <edit-customer v-model="showEditCompany" :currentCompany="selectedCompany" :companyId="selectedCompanyId" @close="showEditCompany = false" />
  <v-list two-line>
    <v-list-item v-model="selected" multiple v-for="obj in kbase"  v-bind:key="obj.id" >
     <v-col cols="9" @click="editItem(obj)"> 
      
      <span class="lst-label">  <v-icon  color="red darken-4">mdi-office-building</v-icon>   </span> <span class="dialogLink">{{obj.title}} </span><br>
       <span class="lst-label">  <v-icon color="red darken-4" >mdi-phone-classic</v-icon></span> {{obj.phone}}

  {{ obj.dateUpdated | moment("MM/D/YYYY") }}

     </v-col>
     <v-col cols="2">
         <router-link :to="'/admin/objs/' + obj.id ">
            <i :class="'fal ' + faIcon(obj.mediaType)"  style="font-size:1.4rem;color: gray"></i>
         </router-link>
     </v-col>
     <v-col>
           <status-icon  @click="toggleCompanyStatus(item.id,item.status)" v-bind:status="obj.status" />
     </v-col>
    </v-list-item>
  </v-list>
 







         <edit-k-base-item v-model="showEditItem" :currentItem="selectedItem" :itemId="selectedItemId" @close="showEditItem = false" />
    </v-container>
    
</div>
</template>

<script>
import { db } from "../db";
import Header from "@/components/Header";
import NewKBaseItem from "./dialogs/NewKBaseItem.vue";
import StatusIcon from "../../components/StatusIcon";
import EditKBaseItem from "./dialogs/EditKBaseItem"
 
export default {
  components: { Header, "new-kbase-item": NewKBaseItem, StatusIcon, EditKBaseItem },
  data() {
    return {
      kbase: [],
      selectedItemId: '',
      selectedItem: {},
      showEditItem: false,
      headers: [
          
        {
          text: "Category",
          align: "start",
          sortable: true,
          value: "category",
        },

        { text: "Type", value: "mediaType" },
        { text: "Title", value: "title" },
        { text: "Last Updated", value: "dateUpdated" },
        { text: "Status"},
        { text:"--"}
      ],
    };
  },
  methods: {
    gotoPage: function(pageName) {
      this.$router.push(pageName);
    },
    editItem(item){
          //alert(CompanyId)
          this.selectedItemId = item.id
          this.selectedItem = {...item}
          console.log(item)
          this.showEditItem = true;
      },
    toggleItemStatus(id,status){
          const newStatus = status =='active' ? 'inactive' : 'active'
          db.doc(`/kbase/${id}`).update({status: newStatus})

    },
    faIcon(mediaType){
        var fa = "fa-file"
        switch(mediaType){
            case "image":
               fa = 'fa-image'
                break;
            case "pdf":
               fa = "fa-file-pdf"
                break;
            case "video":
              fa = "fa-video"
              break;
            case "web":
              fa = "fa-globe"
              break;
        }
        return fa
    }
  },
  firestore: {
    kbase: db.collection("kbase"),
    appSettings: db.doc("appSettings/app"),
  },
};
</script>

