import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
// import AdminHome from '../views/admin/Home.vue'
import AdminUsers from '../views/admin/Users.vue'
import AdminCustomers from '../views/admin/Customers.vue'
import AdminContacts from '../views/admin/Contacts.vue'
import AdminManageKbase from '../views/admin/ManageKBase.vue'
import AdminAllRequests from '../views/admin/AllRequests.vue'
import CustomerHome from '../views/customer/Home.vue'
import CustomerSupportChat from '../views/customer/SupportChat.vue'
import CustomerOrderPart from '../views/customer/OrderPart.vue'
import CustomerPartRequestHistory from '../views/customer/PartRequestHistory.vue'
import Customerrfq from '../views/customer/RFQ.vue'
import CustomerKnowledgeBase from '../views/customer/KnowledgeBase.vue'
import RepHome from '../views/rep/Home.vue'
import RepRequests from '../views/rep/MyRequests.vue'
import RepCustomers from '../views/rep/Customers.vue'
import RepContacts from '../views/rep/Contacts.vue'
import RepSupportChatDetail from '../views/rep/SupportChat.vue'
import RepSupportChatList from '../views/rep/SupportChatList.vue'

import store from '@/store/index'

const checkLogin = function(to: any, from: any , next: any){
 if(!store.state.user.isLoggedIn){
   next({ name: 'login' })
 }
 else{
   next()
 }
  
}


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'login',
     component: () => import('../views/auth/Login.vue') 
  },{
    path: '/admin/home',
    name: 'admin-home',
    component:() => import('../views/admin/Home.vue'),
    beforeEnter: checkLogin
  },
  {
    path: '/admin/users',
    name: 'admin-users',
    component: AdminUsers,
    beforeEnter: checkLogin
  },
  {
    path: '/admin/customers/:companyId',
    name: 'admin-contacts',
    component: AdminContacts,
    beforeEnter: checkLogin
  },
  {
    path: '/admin/customers',
    name: 'admin-customers',
    component: AdminCustomers
  },
  {
    path:'/admin/manage-kbase',
    name:'admin-manage-kbase',
    component:AdminManageKbase,
    beforeEnter: checkLogin
  },
  {
    path:'/admin/all-requests',
    name:'admin-all-requests',
    component:AdminAllRequests,
    beforeEnter: checkLogin
  },
  {
    path:'/customer/home',
    name:'customer-home',
    component:CustomerHome,
    beforeEnter: checkLogin
  },
  {
    path:'/customer/support-chat',
    name:'customer-support-chat',
    component:CustomerSupportChat,
    beforeEnter: checkLogin
  },
  {
    path:'/customer/order-part',
    name:'order-part',
    component:CustomerOrderPart,
    beforeEnter: checkLogin
  },
  {  path:'/customer/request-history',
       name:'request-history',
       component:CustomerPartRequestHistory,
       beforeEnter: checkLogin

  },{
    path:'/customer/rfq',
    name:'rfq',
    component:Customerrfq,
    beforeEnter: checkLogin
  },
  {
    path:'/customer/knowledge-base',
    name:'knowledge-base',
    component:CustomerKnowledgeBase,
    beforeEnter: checkLogin
  },
  {
    path:'/rep/home',
    name:'Rep-Home',
    component:RepHome,
    beforeEnter: checkLogin
  },
  {
    path:'/rep/requests',
    name:'Rep-Requests',
    component:RepRequests,
    beforeEnter: checkLogin
  },
  {
    path:'/rep/customers',
    name:'rep-customers',
    component:RepContacts,
    beforeEnter: checkLogin
  },
  {
    path:'/rep/:companyId/contacts',
    name:'rep-contacts',
    component: RepContacts,
    beforeEnter: checkLogin
  },
  {
    path:'/rep/support-chat',
    name:'rep-supportchat',
    component:RepSupportChatList,
    beforeEnter: checkLogin
  },
  {
    path:'/rep/support-chat/:id',
    name:'rep-supportchat-detail',
    component:RepSupportChatDetail,
    beforeEnter: checkLogin
  }
  
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
