<template>
  <div class="orderpart text-left">
    <div class=" logo text-left">
      <Header title="Customer Help Center"></Header>
    </div>

    <div class="body">
      <h3>Request Part</h3>
      <p>Any Special Notes can go here</p>

      <v-container>
        <v-row>
          <v-col cols="6">
            <v-file-input
              accept="image/*"
              label="Upload Image"
              v-model="partorder.image"
            ></v-file-input
          ></v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label=" Serial Number"
              outlined
              v-model="partorder.serial"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Quantity"
              outlined
              type="number"
              v-model="partorder.qty"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              outlined
              label="Additional Comments"
              v-model="partorder.comments"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>

      <v-row >
          <v-col cols="12" class="image text-center">
           <v-btn color="primary" :disabled="!formValid" @click="save()">Send Request</v-btn>
          </v-col>
       
       
      </v-row>
      <v-snackbar
      color="accent"
      v-model="snackbar"
      :timeout="timeout"
    >
      Request Sent.  Thank You!

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header";
import { mapState } from 'vuex';
import { db } from "../db";

export default {
  components: { Header },
  data() {
    return {
        snackbar: false,
        timeout: 5000,
      partorder: {
         comments: ""

      },
    };
  },
  computed:{
      formValid(){
          return (this.partorder.serial || this.partorder.image && this.partorder.qty) ? true : false
      },
      ...mapState(['user'])
  },
  methods: {
    gotoPage: function(pageName) {
     
      this.$router.push(pageName);
    },
    save() {
        //Read the image if there is one
        if(this.partorder.image){
            const reader = new FileReader()
                reader.readAsDataURL(this.partorder.image)
           reader.onload = ()=> {
            console.log(reader.result);
            this.partorder.imageUrl = reader.result;
           }
        }
      this.partorder.companyId = this.user.company;
      this.partorder.companyName = this.user.companyName;
      this.partorder.contactName = this.user.name;
      this.partorder.contactEmail = this.user.email;
      this.partorder.status = 'new'
      this.partorder.type= 'porder'
      this.partorder.dateCreated = new Date().getUTCDate().toString();

      delete this.partorder.image
    
      db.collection("customerRequests").add(this.partorder)
      this.snackbar = true;
      this.partorder = {}
    
    },
  },
};
</script>
<style scoped>
img.img-fluid {
  width: 90px;
}

.body {
  text-align: center;
}
.text-right span input {
  border-bottom: 1px solid black;
  padding-left: 8px;
}
.text-right span input {
  border-bottom: 1px solid black;
  padding-left: 8px;
}


.head.text-center {
  margin-bottom: 20px;
}
.image textarea {
  float: left;
  width: 40%;
  padding-left: 10px;
  border: 2px solid black;
  margin-left: 40px;
  margin-bottom: 20px;
}

 
</style>
