<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
          NEW KBASE
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          New Knowledge Base Item
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-select
              v-model="kbase.category"
              :items="appSettings.kbaseCategories"
              label="Category"
            >
            </v-select>
            <v-text-field v-model="kbase.title" label="Title"> </v-text-field>
            <v-text-field v-model="kbase.description" label="Description">
            </v-text-field>
            <v-select
              v-model="kbase.mediaType"
              :items="appSettings.mediaTypes"
              label="Type"
            ></v-select>
            <v-text-field label="URL (http://)" v-model="kbase.mediaUrl" truncate-length="15"></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="warning" outlined @click="dialog = false">
            CANCEL
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="save()">
            save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from "../../db";
export default {
  data() {
    return {
      dialog: false,
      reps: [],
      kbase: {},
      appSettings: {},
    };
  },
  methods: {
    save() {
      //BaseEndcode File
      this.kbase.dateCreated = new Date().toISOString();
      this.kbase.dateUpdated = new Date().toISOString();
      this.kbase.status = 'active'
      this.dialog = false;
      console.log(this.kbase)
      db.collection("kbase").add(this.kbase)
      this.kbase = {};
    },
  },
  firestore: {
   
    appSettings: db.doc("appSettings/app"),
  },
};
</script>
<style lang="sass" scoped>
.idk
  color: "red"
</style>
