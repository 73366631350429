/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
<template>
  <div class="adminuser">
    <div class="row">
      <Header title="Lako Rep Panel">
       
       
        <div class="text-center" slot="subheader">
          <small style="color:gray; font-weight:normal; font-family:tahoma"
            >
            <br />
            <em>Contacts</em></small
          >
        </div>
      </Header>
    </div>

   
    <v-container>
  <h4> Contacts</h4> 
  <new-contact ></new-contact> 
      <reset-password v-model="showEditPassword" :currentUser="selectedUser" :userId="selectedUserId" @close="showEditPassword = false" />
  <v-list two-line>
    <v-list-item  multiple v-for="obj,index in contacts"  v-bind:key="obj.id" >
     <v-col cols="8" > 
      
      <div class="lst-label" > <v-icon color="red darken-4" >mdi-account</v-icon></div><div style="display:inline"> {{obj.name}} </div> <br>
      <span class="lst-label">  <v-icon  color="red darken-4">mdi-office-building</v-icon>  </span>   {{obj.companyName}} <br>
      <span class="lst-label"> <v-icon color="red darken-4" >mdi-email</v-icon></span> {{obj.email}} <br>

     </v-col>
     <v-col :id="index" class="dialogLink"  @click="editUserPassword(obj)"  cols="3">
        Reset Password
     </v-col>
     <v-col @click="toggleUserStatus(obj.id,obj.status)">
           <status-icon  v-bind:status="obj.status" />
     </v-col>
    </v-list-item>
  </v-list>
  
</v-container>
  </div>
</template>

<script>
import Header from "@/components/Header";
import { db } from "../db";
import StatusIcon from "../../components/StatusIcon";
import ResetPassword from '../admin/dialogs/ResetPassword.vue';
import NewContact from "@/views/admin/dialogs/NewContact.vue"

export default {
  components: { Header,  StatusIcon, ResetPassword, NewContact},
  data() {
    
    return {
      customer: {},
      contacts: [],
      selectedUser: {user:"Levar"},
      selectedUserId: '',
      showEditUser: false,
      companies:[],
      showEditPassword: false,
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },

        { text: "Email", value: "phone" },

        { text: "Status", value: "status" },
        { text: "--" },
      ],
    };
  },
  computed: {
    currentCompanyId() {
      return this.$route.path.split("/")[2];
    },
  },
  methods:{
    showNewContact(){
      console.log("ok")
    },
  editUser(userId){
          //alert(userId)
          this.selectedUserId = userId.id
          this.selectedUser = {...userId}
          this.showEditUser = true;
   },

      
      editUserPassword(userId){
          //alert(userId)
          this.selectedUserId = userId.id
          this.selectedUser = {...userId}
          this.showEditPassword = true;
          console.log(this.showEditPassword)
  },
      toggleUserStatus(userId,status){ 
          const newStatus = status =='active' ? 'inactive' : 'active'
          db.doc(`/appUsers/${userId}`).update({status: newStatus})

      }
  },
  mounted() {
    const docUri = "/companies/" + this.currentCompanyId;
    
    this.$bind("customer", db.doc(docUri));
    this.$bind(
      "contacts",
      db.collection("appUsers").where("role", "==", "customer")
    );
  },
  firestore: {
    //companies: db.collection("companies"),
    //  contacts: db.collection("contacts") 
  },
};
</script>
