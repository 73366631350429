<template>
  <div class="text-center">
  
    <v-dialog v-model="show" width="500">
     

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Reset Password For: {{ user.name}}
        </v-card-title>

        <v-card-text>
       
          <v-form
           ref="form"
          >
            <v-text-field
              v-model="newPassword"
              :rules="[v => !!v || 'Password is required']"
              label="Password"
            ></v-text-field>
           
           
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="warning" outlined @click="close()">
            CANCEL
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="save()">
            save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from "../../db";

export default {
  props:['value', 'currentUser', 'userId'],
  data() {
    return {
      newPassword: ""
     
    };
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('close', value)
      }
    },
    user: {
        get() { return this.currentUser }
    }
  },
  methods:{
      save(){
         if(this.validate()){
           this.user.status = 'active'
           this.user.password = btoa(this.newPassword)
           this.addToFirestore()
           this.close()
           
         }
          
      },
      validate() {
       return this.$refs.form.validate()
      },
      async addToFirestore(){
          
        await db.doc(`appUsers/${this.userId}`).update(this.user)
      },
      close(){
           this.$emit('close', false)
      }
  }
};
</script>
<style lang="sass" scoped>
 .idk 
   color: "red"
</style>
 