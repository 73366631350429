<template>
  <div>
    <div class="row">
      <Header title="Administrator Panel">
        <new-user-dialog></new-user-dialog>
      </Header>
    </div>

<v-container>
  <h4> LAKO Team</h4>
  <new-user-dialog></new-user-dialog>
    <!-- <edit-customer v-model="showEditCompany" :currentCompany="selectedCompany" :companyId="selectedCompanyId" @close="showEditCompany = false" /> -->
  <v-list two-line>
    <v-list-item v-model="selected" multiple v-for="customer in users"  v-bind:key="customer.id" >
     <v-col cols="6" @click="editUser(customer)"> 
      
      <span class="lst-label"> <v-icon color="red darken-4" >mdi-account</v-icon></span> <span class="dialogLink">{{customer.name}} </span><br>
       <span class="lst-label">  <v-icon color="red darken-4" >mdi-cellphone-basic</v-icon></span> {{customer.sms}}<br>
         <span class="lst-label">  <v-icon color="red darken-4" >mdi-email</v-icon></span> {{customer.email}}
     </v-col>
     <v-col>{{customer.region}}</v-col>
     <v-col cols="2" @click="editUserPassword(customer)">
              <v-icon color="blue darken-4" >mdi-lock-reset</v-icon>
     </v-col>
     <v-col>
           <status-icon  @click="toggleUserStatus(item.id,item.status)" v-bind:status="customer.status" />
     </v-col>
    </v-list-item>
  </v-list>
</v-container>
    <edit-user v-model="showEditUser" :currentUser="selectedUser" :userId="selectedUserId" @close="showEditUser = false" />
     <reset-password v-model="showEditPassword" :currentUser="selectedUser" :userId="selectedUserId" @close="showEditPassword = false" />
  </div>
</template>

<script>
import { db } from "../db";
import Header from "../../components/Header.vue";
import StatusIcon from "../../components/StatusIcon";
import NewUserDialog from "./dialogs/NewUser";
import EditUser from './dialogs/EditUser';
import ResetPassword from './dialogs/ResetPassword'

export default {
  components: { "new-user-dialog": NewUserDialog, Header,StatusIcon, EditUser, ResetPassword },
  data() {
    return {
      selectedUser: {user:"Levar"},
      selectedUserId: '',
      showEditUser: false,
      selected: '',
      showEditPassword: false,

      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },

        { text: "Email", value: "email" },
        { text: "Mobile/SMS", value: "sms" },
        { text: "Status", value: "status" },
        { text: "--"}
      ],
      users: [],
      appSetting: {},
    };
  },
  methods:{
      editUser(userId){
          //alert(userId)
          this.selectedUserId = userId.id
          this.selectedUser = {...userId}
          this.showEditUser = true;
      },
      editUserPassword(userId){
          //alert(userId)
          this.selectedUserId = userId.id
          this.selectedUser = {...userId}
          this.showEditPassword = true;
      },
      toggleUserStatus(userId,status){
          
          const newStatus = status =='active' ? 'inactive' : 'active'
          console.log(userId, status, newStatus)
          db.doc(`/appUsers/${userId}`).update({status: newStatus})

      }
  },

  firestore: {
    users: db.collection("appUsers").where("role", "==", "lako"),
    appSettings: db.doc("appSettings/app"),
  },
};
</script>
