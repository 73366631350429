<template>
 <div class="homepage">
    <Header showNotifications="true" title="Lako Rep Panel">{{user}}</Header>
    
    <div class="row">
        <div class="col-6 text-left">
          <div class="lako text-center" @click="gotoPage('/rep/support-chat')">
              <i class="fas fa-comments"></i>
              <h2>Customer Support</h2>
              </div>
        </div>
        <div class="col-6 text-left" @click="gotoPage('/rep/customers')">
          <div class="lako text-center">
             
              <i class="fas fa-users"></i>
              <h2>Contacts</h2>
              </div>
        </div>
       
        <div class="col-12 text-left" @click="gotoPage('/rep/requests')">
          <div class="lako text-center">
               <i class="fas fa-question"></i>
              <h2>My Requests</h2>
              </div>
        </div>
    </div>
</div>
</template>
<script>
import Header from '@/components/Header'
import { db } from '../db'
import { mapState } from 'vuex'
export default {
    data(){
        return {
            pageName:'',
           
        }
    },
    components: {Header},
    mounted(){
       const playerId = localStorage.playerId;
       console.log(playerId, this.user.id)
        //update the user with this playerId
        db.collection("appUsers").doc(this.user.id).update({playerId})
    },
    computed:{
            ...mapState(['user'])
    },
    methods:{
        gotoPage: function(pageName){
          console.log(pageName)
          
          this.$router.push(pageName)
      }
    }
}
</script>
<style scoped>
.col-6.text-left {
    float: left;
    width: 50%;
    margin-top: 20px;
    margin-bottom:130px;
}


.fas {
    font-size: 2rem;
    color: maroon;
    margin-bottom: 11px;
}
</style>