<template>
  <div class="adminuser" style="margin: 0px; width: 100%; padding: 0px">
    <div class="row">
      <Header title="Administrator Panel">
       
        <div class="text-center" slot="subheader">
          <small style="color:gray; font-weight:normal; font-family:tahoma"
            >{{ customer.name }}
            <br />
            <em>Contacts</em></small
          >
        </div>
      </Header>
    </div>

  

<v-container>
       <edit-user v-model="showEditUser" :currentUser="selectedUser" :userId="selectedUserId" @close="showEditUser = false" />
        <reset-password v-model="showEditPassword" :currentUser="selectedUser" :userId="selectedUserId" @close="showEditPassword = false" />
    <edit-contact-assign v-model="showEditContactAssign" :currentContact="selectedContact" :contactId="selectedContactId" @close="showEditContactAssign = false" />
   
  <h4> Contacts</h4>
    <new-contact :companyId="currentCompanyId" :companyName="customer.name"></new-contact>
  <v-list two-line>
    <v-list-item multiple v-for="obj in contacts"  v-bind:key="obj.id" >
     <v-col cols="8" @click="editUser(obj)"> 
      
      <span class="lst-label"> Name: </span> <span class="dialogLink"> {{obj.name}} </span><br>
      
      <span class="lst-label"> Email: </span> {{obj.email}} <br>

     </v-col>
     <v-col :id="obj.id" class="dialogLink"  @click="editContactAssign(obj)" cols="3">
          <span class="lst-label"> Assigned To: </span>
              {{ obj.repName }}
         
     </v-col>
     <v-col @click="toggleUserStatus(obj.id,obj.status)" > 
           <status-icon  v-bind:status="obj.status" />
     </v-col>
    </v-list-item>
  </v-list>
</v-container>
  </div>
</template>

<script>
import Header from "@/components/Header";
import { db } from "../db";
import NewContact from "./dialogs/NewContact.vue";
import EditUser from './dialogs/EditUser';
import StatusIcon from "../../components/StatusIcon";
import ResetPassword from './dialogs/ResetPassword'
import EditContactAssign from './dialogs/EditContactAssign.vue';
export default {
  components: { Header, NewContact, StatusIcon, EditUser, ResetPassword, EditContactAssign },
  data() {
    return {
      customer: {},
      customerName: '',
      contacts: [],
      selectedUser: {user:"Levar"},
      selectedUserId: '',
      selectedContactId:"",
      selectedContact: "",
      showEditUser: false,
      showEditPassword: false,
      showEditContactAssign: false,
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },

        { text: "Email", value: "phone" },
        { text: "Rep Name", value:"repName"},

        { text: "Status", value: "status" },
        {text :"Region"},
        { text: "--" },
      ],
    };
  },
  computed: {
    currentCompanyId() {
      return this.$route.path.split("/")[3];
    },
  },
  methods:{
  
  editUser(userId){
          //alert(userId)
          this.selectedUserId = userId.id
          this.selectedUser = {...userId}
          this.showEditUser = true;
   },
      editUserPassword(userId){
          //alert(userId)
          this.selectedUserId = userId.id
          this.selectedUser = {...userId}
          this.showEditPassword = true;
  },
  editContactAssign(contact){
          //alert(contactId)
          this.selectedContactId = contact.id
          this.selectedContact = {...contact}
          this.showEditContactAssign = true;
      },
      toggleUserStatus(userId,status){ 
       
          const newStatus = status =='active' ? 'inactive' : 'active'
          db.doc(`/appUsers/${userId}`).update({status: newStatus})

      }
  },
  mounted() {
    const docUri = "companies/" + this.currentCompanyId;
    
    this.$bind("customer", db.doc(docUri));
    this.$bind(
      "contacts",
      db.collection("appUsers").where("companyId", "==", this.currentCompanyId)
    );
    console.log("mounted", this.customer, docUri)
  },
  firestore: {
    companies: db.collection("companies"),
    //   contacts: db.collection("contacts").where("companyId", "==",this.currentCompanyId)
  },
};
</script>
