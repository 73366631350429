<template>
  <div class="Home">
    <div class=" logo text-left">
      <Header title="Customer Help Center"></Header>
    </div>
    <div class="bodyknowledge" v-for="cat in categories">
      <h4 >{{cat}}</h4>
      <table>
        <tbody v-for="article in kbaseItems">
        <tr  v-if="article.category ==cat">
          
      <td class="pa-5">
            <a target="_new" :href="article.mediaUrl" >
              <i :class="getMediaIcon(article.mediaType)"></i>   
            </a>
        </td>
          <td style="width: 93%;">
              <h5>{{article.title}}</h5>
              <p>{{article.description}} </p>
              <hr  >
           <div>
               <small>Last Modified: {{article.dateUpdated | moment("from","now")}}</small>
           </div>

            <small class="text-right">Created Modified: {{article.dateUpdated | moment("from","now")}}</small>
          </td>
          
          
        </tr>
        </tbody>
        
      </table>

       
      
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
import { db } from '../db'
export default {
    components:{Header},
    data(){
        return {
            kbaseItems:[],
            appSettings:[]
        }
    },
    computed:{
        categories(){
            return this.appSettings.kbaseCategories
        }
    },
    methods:{
        getMediaIcon(type){
            switch(type){
                case 'pdf':
                  return 'fal fa-file-pdf'
                case 'image':
                  return 'fal fa-camera'
                case 'video':
                  return 'fal fa-video'
                case 'web':
                  return 'fal fa-link'
            }
            
        }
    },
    
    firestore:{
        kbaseItems: db.collection('kbase').orderBy("category"),
        appSettings: db.doc("appSettings/app")
    }
}
</script>
<style scoped>
.text-right span input {
  border-bottom: 1px solid black;
  padding-left: 8px;
}
.bodyknowledge {
  padding: 0 16%;
}
td {
  padding-bottom: 20px;
}

table {
  width: 100%;
  padding: 15px 30px;
}
.fal{
    font-size: 2.5rem;
}
hr {
  border-top: 1px solid #eee;
}
h4 {
    color: #0D3748;
}
h5 {
    color: maroon
}
</style>
