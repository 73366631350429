<template>
  <div class="text-center">
  
    <v-dialog v-model="show" width="500">
     

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Edit Knowledgebase Item
        </v-card-title>

        <v-card-text>
         <v-form ref="form">
            <v-select
              v-model="kbase.category"
              :items="appSettings.kbaseCategories"
              label="Category"
            >
            </v-select>
            <v-text-field v-model="kbase.title" label="Title"> </v-text-field>
            <v-textarea v-model="kbase.description" label="Description">
            </v-textarea>
            <v-select
              v-model="kbase.mediaType"
              :items="appSettings.mediaTypes"
              label="Type"
            ></v-select>
            <v-text-field label="URL (http://)" v-model="kbase.mediaUrl" truncate-length="15"></v-text-field>
          </v-form>
          
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="warning" outlined @click="close()">
            CANCEL  
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="save()">
            save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from "../../db";

export default {
  props:['value', 'currentItem', 'itemId'],
  data() {
    return {
      appSettings: {}

     
    };
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('close', value)
      }
    },
    kbase: {
        get() { return this.currentItem }
    }
  },
  methods:{
      save(){
         if(this.validate()){
           this.addToFirestore()
           this.close()
           
         }
          
      },
      validate() {
       return this.$refs.form.validate()
      },
      async addToFirestore(){
         this.kbase.dateUpdated = new Date().toISOString();
        await db.doc(`kbase/${this.itemId}`).update(this.kbase)
      },
      close(){
        
           this.$emit('close', false)
      }
  },
  firestore: {
    appSettings: db.doc('appSettings/app')
  },
};
</script>
<style lang="sass" scoped>
 .idk 
   color: "red"
</style>
 