<template>
    <span> 
       
       
        <v-icon v-if="status =='active'" color="primary">mdi-check</v-icon>
        <v-icon v-if="status !='active'">mdi-minus-circle</v-icon>
     
    </span>
</template>
<script>
export default {
    props:[ "status"],
    data(){
        return {

        }
    }
}
</script>
<style lang="scss" scoped>
.active {
    color: darkgreen;
}
</style>