<template>
  <v-app >
   <v-content>
     <router-view></router-view>
   </v-content>
  </v-app>
</template>

<script >
export default({
  name: 'App',
  data: () => ({
    //
  }),
});
</script>
<style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Raleway');
  @import url('./assets/fontawesome/css/all.min.css');
  * {
    font-family: 'Tahoma';
    font-family: 'Raleway', Calibri;
     color:#0E3849;
    
  }
  html {
  --scrollbarBG: #0E2849;
  --thumbBG: #AE1542;
}
body::-webkit-scrollbar {
  width: 11px;
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
  .v-data-table{
    margin-top: 11px;
  }
   .v-data-table-header{
    background-color: #a2c1e7  !important;
    color: white  !important;
  }
  .dialogLink{
    color: rgb(36, 146, 255);
    text-decoration: underline;
  }
  .lst-label {
    display: inline-block;
    font-weight: bold;
    font-size: .9rem;
    color: #0E3849;
    
    min-width: 85px;
  }
</style>