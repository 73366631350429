<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
          NEW USER
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          New Lako User
        </v-card-title>

        <v-card-text>
          This form is for creating LAKO TOOL users only.
          <v-form
           ref="form"
          >
            <v-text-field
              v-model="user.name"
              :rules="[v => !!v || 'Name is required']"
              label="Name"
            ></v-text-field>
            <v-text-field
              v-model="user.email"
             :rules="[v => !!v || 'Email is required']"
              
              label="Email"
            ></v-text-field>
            <v-text-field
              v-model="user.sms"
             
              
              label="Mobile/SMS"
            ></v-text-field>
            <v-text-field
              v-model="user.password"
              :counter="25"
              class="idk"
              color="accent"
              label="Password"
              :rules="[v => !!v || 'Password is required']"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="warning" outlined @click="dialog = false">
            CANCEL
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="save()">
            save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from "../../db";

export default {
  data() {
    return {
      dialog: false,
      user:{}
    };
  },
  methods:{
      save(){
         if(this.validate()){
           this.user.status = 'active'
           this.user.role = 'lako'
           this.user.password = btoa(this.user.password)
           this.addToFirestore()
           this.dialog = false
           this.user = {}
         }
          
      },
      validate() {
       return this.$refs.form.validate()
      },
      async addToFirestore(){
        await db.collection("appUsers").add(this.user)
      }
  }
};
</script>
<style lang="sass" scoped>
 .idk 
   color: "red"
</style>
 