<template>
  <div class="adminuser">
    <div class="row">
      <div>
        <Header title="Administrator Panel">
          <new-customer-dialog></new-customer-dialog>
        </Header>
      </div>
    </div>
 

<v-container>
  <h4> Customers</h4>
  <new-customer-dialog ></new-customer-dialog>
    <edit-customer v-model="showEditCompany" :currentCompany="selectedCompany" :companyId="selectedCompanyId" @close="showEditCompany = false" />
  <v-list two-line>
    <v-list-item v-model="selected" multiple v-for="customer in customers"  v-bind:key="customer.id" >
     <v-col cols="9" @click="editCompany(customer)"> 
      
      <span class="lst-label">  <v-icon  color="red darken-4">mdi-office-building</v-icon>   </span> <span class="dialogLink">{{customer.name}} </span><br>
       <span class="lst-label">  <v-icon color="red darken-4" >mdi-phone-classic</v-icon></span> {{customer.phone}}
     </v-col>
     <v-col cols="2">
         <router-link :to="'/admin/customers/' + customer.id ">
           <v-icon color="blue lighten-1"> mdi-account-multiple</v-icon>
         </router-link>
     </v-col>
     <v-col @click="toggleCompanyStatus(item.id,item.status)" > 
           <status-icon  v-bind:status="customer.status" />
     </v-col>
    </v-list-item>
  </v-list>
</v-container>

  </div>
</template>

<script>
import { db } from "../db";
import Header from "@/components/Header";
import NewCustomer from "./dialogs/NewCustomer.vue";
import StatusIcon from "../../components/StatusIcon";
import EditCustomer from "./dialogs/EditCompany"
export default {
  components: { Header, "new-customer-dialog": NewCustomer, StatusIcon, EditCustomer },
  data() {
    return {
      selectedCompanyId: '',
      selectedCompany: {companyName:""},
      showEditCompany: false,
      customers: [],
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },

        { text: "Phone", value: "phone" },
        { text: "Rep", value: "repName" },
        { text: "Status", value: "status" },
        { text:"--"}
      ],
    };
  },
  methods: {
    gotoPage: function(pageName) {
      this.$router.push(pageName);
    },
    editCompany(company){
          //alert(CompanyId)
          this.selectedCompanyId = company.id
          this.selectedCompany = {...company}
          this.showEditCompany = true;
      },
      toggleCompanyStatus(companyId,status){
          
          const newStatus = status =='active' ? 'inactive' : 'active'
          db.doc(`/companies/${companyId}`).update({status: newStatus})

      },
  },
  firestore: {
    customers: db.collection("companies"),
    appSettings: db.doc("appSettings/app"),
  },
};
</script>


